/* ChatPage.css */

/* ChatPage container */
.chat-page {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #051622;
    height: 100%;
    font-weight: bold;
}

.chat-page-header {
    text-align: center; /* Center the text */
    width: 100%; /* Full width */
    padding: 10px 0; /* Padding to ensure space around the text */
    background-color: #051622; /* Background color */
    color: #DEB992; /* Text color */
    font-size: 2em; /* Adjust the font size as needed */
    margin-bottom: 20px; /* Space between the header and the rest of the content */
  }

.top-right {
    position: absolute;
    top: 20px; /* Adjust the top position as needed */
    right: 20px; /* Adjust the right position as needed */
    z-index: 1; /* Ensure it's above other content */
}

/* Back button styles */
.back-button {
  background-color: #1BA098;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px;
  margin-top: 10px; /* Adjust margin as needed to position the button */
  font-weight: bold;
}

/* Messages list */
.messages-list {
    flex: 1;
    overflow-y: auto;
 
    display: flex;
    flex-direction: column; /* Ensure messages stack vertically */

    /* Custom scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
    border-radius: 10px; /* Rounded corners for the scrollbar */
    background-color: transparent; /* Transparent background on the scrollbar itself */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* Transparent track */
    border-radius: 10px; /* Rounded corners on the track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00a699; /* Your desired shade of green for the thumb */
    border-radius: 10px; /* Rounded corners on the thumb */
    border: 3px solid #022b3a; /* Space between thumb and track */
  }
  padding-right: 15px; /* Adjust the padding to make sure messages don't get under the scrollbar */
  padding-bottom: 20px; /* Extra padding at the bottom */

}

.message {
    background-color: #E6F7EC;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
    max-width: 75%; /* Adjust width to your preference */
    word-wrap: break-word;
    align-self: flex-start;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* subtle shadow */
    border: 1px solid #d1d1d1; /* light border */
    position: relative;
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* smooth transition for hover effect */
}

.message:hover {
    transform: translateY(-2px); /* subtle lift effect on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* stronger shadow on hover */
}

/* User message style */
.message.user {
    background-color: #DEB992;
    color: black;
    align-self: flex-end;
    font-weight: bold;
    padding-right: 20px;
    /* You can add a different shadow color if you like */
    box-shadow: 0 4px 8px rgba(112, 111, 111, 0.15);
}

/* Other user message style */
.message.other {
    background-color: #1BA098;
    color: white;
    align-self: flex-start;
    /* Consistent shadow with user messages */
    margin-left: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Message form container */
.message-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F4F4F4;
    padding: 10px;
    border-top: 1px solid #ccc;
    font-weight: bold;
}

.feedback-form-container {
    background: #051622; /* Your chosen color for the feedback form */
    padding: 20px;
    /* Make sure this container is not flex or you need to set flex: none; */
  }
  
  .message-footer {
    display: flex;
    justify-content: space-between; /* Aligns date to left and time to right */
    margin-top: 8px; /* Space between text and footer */
    padding: 4px 0; /* Add padding to the top and bottom */
  }
  
  /* Style for the time within the footer of a user message */
  .message.user .message-footer .message-time {
    margin-left: auto; /* This will push the time to the right */
    font-size: 0.8em;
    color: #000000; /* Black color for user class */
    background: rgba(0, 0, 0, 0.185); /* Remove any background styling */
    padding: 3px 7px;
    border-radius: 8px;
  }
  
  /* Style for the time within the footer of an other message */
  .message.other .message-footer .message-time {
    margin-left: auto; /* This will push the time to the right */
    font-size: 0.8em;
    color: #FFFFFF; /* White color for other class */
    background: rgba(0, 0, 0, 0.185); /* Remove any background styling */
    padding: 3px 7px;
    border-radius: 8px;
  }
  