.settings {
  text-align: center;
  flex: 1;
  background: #051622; /* Dark background */
  padding: 20px;
  color: #DEB992; /* Light text for better contrast */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  overflow: auto;
}

.toggle {
  position: relative;
  display: flex;
  justify-content: space-between; /* Keep label to the left and toggle button to the right */
  align-items: center;
  background-color: #00a699; /* Toggle background */
  padding: 10px;
  border-radius: 15px; /* Rounded corners for the rectangle */
  margin-bottom: 2rem;
  margin-top: 2rem;
  max-width: 700px;
  width: 80%; /* Set the width to 80% of the parent container */
  margin-left: auto; /* These two margin properties will */
  margin-right: auto; /* center the element horizontally */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.65);
  font-weight: bold;
}

/* Toggle hover effect */
.toggle:hover {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
}

.toggle label {
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  flex-grow: 1; /* Ensure label takes up all available space */
  display: flex;
  justify-content: center; /* Center text horizontally */
  align-items: center; /* Center text vertically */
  margin-bottom: 0; /* Reset the bottom margin */
}

.toggle input {
  position: absolute;
  width: 60px; /* Same as the width of the slider */
  right: 10px; /* Align to the right with the same padding as .toggle */
  opacity: 0;
  cursor: pointer;
  height: 34px; /* Same as the height of the slider */
}


.slider {
  position: absolute; /* Absolute position to align properly */
  right: 10px; /* Align to the right with the same padding as .toggle */
  width: 60px; /* Width of the slider */
  height: 34px; /* Height of the slider */
  background-color: #ccc; /* Default background */
  border-radius: 34px; /* Rounded borders */
  transition: 0.4s; /* Smooth transition for sliding effect */
}

.slider:before {
  content: "";
  position: absolute;
  height: 26px; /* Height of the circle */
  width: 26px; /* Width of the circle */
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.settings input:checked + .slider {
  background-color: #DEB992; /* Slider color when checked */
}

.settings input:checked + .slider:before {
  transform: translateX(26px); /* Distance circle moves when checked */
}

/* Focus styles for accessibility */
.settings input:focus + .slider {
  box-shadow: 0 0 1px #00a699;
}

/* Input fields styling */
.settings input[type="text"] {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 2px solid #00a699; /* Example color */
  background-color: #0c2d3d; /* Input background */
  color: #DEB992; /* Input text color */
  font-size: 18px;
  outline: none;
}

/* Input group for side-by-side inputs */
.settings .input-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.settings .input-group input[type="text"] {
  width: calc(50% - 10px); /* Adjust the width calculation if there's a gap or overlap */
}

/* Button styling */
.settings button {
  background-color: #00a699; /* Button background */
  color: white; /* Button text color */
  padding: 12px 20px;
  border-radius: 20px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  outline: none;
  margin-bottom: 20px;
  transition: background-color 0.3s;
  width: auto; /* Adjust if you want full width or auto width for buttons */
}

.settings button:hover {
  background-color: #00897b; /* Darker shade on hover */
}

/* Large button styling */
.settings .large-btn {
  width: 100%; /* Full width for large buttons */
  /* Other styles same as button */
}

/* Save button specific styling if needed */
.settings .save-btn {
  /* Custom styles for save button if different from other buttons */
}

.field {
  margin-bottom: 1rem;
}

.field label {
  display: block;
  color: #DEB992;
  font-size: 16px;
  text-align: left; /* Aligns label to the left */
  margin-bottom: 0.5rem;
}

.field input[type="text"],
.field input[type="tel"],
.field input[type="email"],
.field select {
  width: 100%;
  padding: 12px;
  margin-bottom: 1rem;
  border-radius: 20px;
  border: 1px solid #00a699;
  background-color: #1B2130; /* Darker background for inputs */
  color: #DEB992;
  font-size: 16px;
}

.checkbox-custom {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.checkbox-input {
  accent-color: #00a699;
  margin-right: 0.5rem;
  cursor: pointer;
}

.checkbox-label {
  cursor: pointer;
  user-select: none;
  color: #DEB992;
  font-size: 16px;
}

/* Custom checkbox styling */
.checkbox-input + .checkbox-label:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 3px;
  margin-right: 10px;
  border: 1px solid #00a699;
}

.checkbox-input:checked + .checkbox-label:before {
  background-color: #00a699;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path fill="white" d="M1,9 L3,9 L7,13 L15,5 L17,7 L7,17 z"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
}

/* Hide the default checkbox */
.checkbox-input {
  opacity: 0;
  position: absolute;
}

.checkbox-input:focus + .checkbox-label:before {
  outline: 2px solid #00a699;
}


.checkbox-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  color: #DEB992;
  font-size: 16px;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 10px;
  accent-color: #00a699; /* Color the checkbox */
}

.consent-checkbox label {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

/* Button styles */
.settings button,
.settings input[type="submit"] {
  background-color: #00a699;
  color: white;
  padding: 12px 20px;
  border-radius: 20px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  width: 100%; /* Full width buttons */
  margin-bottom: 20px;
  transition: background-color 0.3s;
}

.settings button:hover,
.settings input[type="submit"]:hover {
  background-color: #00897b;
}

/* Save Contact button specific styles if needed */
.save-contact-btn {
  margin-top: 1rem;
}

.contact-list {
  width: 100%;
  max-width: 600px;
  margin: 2rem auto;
  background: #1B2130;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 1rem;
  max-height: 650px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.65);

  flex-direction: column;
  align-items: center;
  flex: 1; /* Takes up only necessary space  */
  max-width: 60%; /* Adjust based on your design preference */
  /* Other styles remain unchanged */
  margin-right: 10px;
  padding: 25px;
  overflow-y: auto; /* Enable vertical scrolling */

  /* This will remove the scrollbar as mentioned */
  &::-webkit-scrollbar {
    display: none;
  }
}

.contact-list-scrollable {
  max-height: calc(100% - 40px); /* Adjust the height to account for the h1 height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 5px; /* Adjust as necessary to avoid content clipping */
  
  /* Custom scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
    border-radius: 10px; /* Rounded corners for the scrollbar */
    background-color: transparent; /* Transparent background on the scrollbar itself */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* Transparent track */
    border-radius: 10px; /* Rounded corners on the track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00a699; /* Your desired shade of green for the thumb */
    border-radius: 10px; /* Rounded corners on the thumb */
    border: 3px solid #1B2130; /* Space between thumb and track */
  }

  /* Custom scrollbar for Firefox */
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: #00a699 #1B2130; /* thumb and track color for Firefox */
}


/* Now apply your custom scrollbar styles to .contact-list-scrollable instead of .contact-list */
.contact-list-scrollable::-webkit-scrollbar {
  /* Your custom scrollbar styles */
}

.contact-list-scrollable {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #00a699 #1B2130; /* thumb and track color */
}


.contact-item {
  display: flex;
  justify-content: space-around; /* Evenly space items within the container */
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #00a699;
  color: #DEB992;
}


.contact-item:last-child {
  border-bottom: none; /* Removes the border for the last item */
}

.contact-info {
  flex-grow: 1; /* Allows the contact information to fill the space */
  margin-right: 0 10px; /* Space between contact info and buttons */
}

.contact-name,
.contact-relationship {
  text-align: left; /* Aligns the text to the left */
  white-space: nowrap; /* Prevents the text from wrapping */
}

.edit-contact-btn {
  background-color: #00796b; /* Distinct color for edit button */
}

.remove-contact-btn {
  background-color: #c62828; /* Distinct color for remove button */
}

.edit-contact-btn,
.remove-contact-btn {
  flex: 1; /* Allow the buttons to grow */
  max-width: 120px; /* Maximum width to prevent buttons from growing too large */
  padding: 10px 15px;
  margin: 0 10px; /* Ensure there is space between buttons */
  border-radius: 5px;
  border: none;
  font-size: 0.875rem;
  text-align: center;
}



.contact-item-buttons {
  display: flex;
  justify-content: flex-end; /* Align buttons to the end */
  gap: 15px; /* Space between buttons */
}


.remove-contact-btn:hover {
  background-color: #b71c1c; /* Darker color on hover for remove button */
}


.content-layout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 2rem; /* Add space above the layout */
}

.contacts-section {
  display: flex;
  justify-content: space-between; /* Distribute space between the two elements */
  align-items: stretch; /* Make the items stretch to fill the container */
  gap: 20px; /* Add a gap between the two elements */
}

.new-contact-form {
  flex: 1;
  max-width: 100%; /* Adjust based on your design preference */
  background-color: #1B2130;
  padding: 25px;
  border-radius: 15px;
  margin: 2rem auto; /* Centers the table on the page with margin top and bottom */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.65);
  /* Add styles for the form if needed */
}


.contacts-section > *:last-child {
  margin-right: 0;
}

/* This will make the last child of each section align to the bottom */
.contact-list > :last-child, 
.new-contact-form > :last-child {
  margin-top: auto;
}

.settings .update-notifications {
  flex: 1;
  max-width: 100%; /* Adjust based on your design preference */
  background-color: #1B2130;
  padding: 10px;
  border-radius: 15px;
  margin-top: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.65);
  /* Add styles for the form if needed */
}


/* Add these CSS styles to your existing CSS file */

/* Style for the "Patient ID" section */
.patient-id {
  background-color: #1B2130; /* Background color */
  border-radius: 15px; /* Rounded corners */
  padding: 20px; /* Padding inside the section */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.65); /* Stronger box shadow for more depth */ /* Box shadow for a subtle elevation effect */
  text-align: center; /* Center-align text inside the section */
  display: flex; /* Use flexbox to center align items vertically and horizontally */
  flex-direction: column; /* Stack items vertically */
}



.patient-id h1 {
  font-size: 24px; /* Heading font size */
  color: #DEB992; /* Heading text color */
}

/* Style for the patientID container */
.patient-id-container {
  background-color: #00a699; /* Background color for patientID container */
  padding: 10px; /* Padding inside the container */
  border-radius: 10px; /* Rounded corners for the container */
  text-align: center; /* Center-align text inside the container */
  margin-top: 20px; /* Spacing from the "Patient ID" heading */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.65);
}

.patient-id-container h1 {
  font-size: 24px; /* Font size for the patientID */
  color: #DEB992; /* Text color for the patientID */
}



@media (max-width: 768px) {
  /* ... rest of the media query styles remain unchanged ... */

  .edit-contact-btn,
  .remove-contact-btn {
    margin-top: 5px; /* Adds space between the buttons and contact info on small screens */
    width: 48%; /* Adjust the width so two buttons fit side by side on small screens */
    margin-right: 2%; /* Adds a small gap between buttons */
  }

  .contact-item {
    flex-wrap: wrap; /* Allows items to wrap to next line as needed */
  }

  .contact-item > * {
    flex: 1 1 48%; /* Ensures items take up to 48% of the width */
    margin-bottom: 10px; /* Adds space below items when they wrap */
  }

  /* Adjust the last button's margin to ensure alignment */
  .contact-item > *:last-child {
    margin-right: 0;
  }
}

/* Targeting the autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1B2130 inset !important;
    -webkit-text-fill-color: #DEB992 !important;
}

/* For Firefox */
input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus {
    box-shadow: 0 0 0 30px #1B2130 inset !important;
    text-fill-color: #DEB992 !important;
}