/* ToggleSwitch.css */
.toggle-switch {
    display: flex;
    border-radius: 20px; /* Rounded corners for the toggle switch background */
    background-color: #151a1f; /* Dark background for the toggle switch */
    padding: 5px; /* Padding inside the toggle switch */
  }
  
  .toggle-option {
    background-color: transparent; /* No background for individual options */
    color: #DEB992;
    border: none; /* No border */
    padding: 10px 20px; /* Padding inside buttons */
    margin: 0; /* No margin between buttons */
    border-radius: 15px; /* Rounded corners for buttons */
    cursor: pointer;
    outline: none; /* Removes the outline */
    transition: background-color 0.3s, opacity 0.3s; /* Smooth transition for background color and opacity */
    font-size: 16px; /* Match the font size to your design */
  }
  
  .toggle-option.selected {
    background-color: #1BA098; /* Highlighted background for selected option */
    color: #DEB992; /* White text for selected option */
    border-radius: 15px; /* Ensure rounded corners for the selected option */
    opacity: 1; /* Full opacity for selected option */
  }
  
  .toggle-option:not(.selected):hover {
    opacity: 0.7; /* Slightly transparent on hover for unselected options */
  }
  